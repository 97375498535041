/* Base style for the button */
.show-steps,
.hide-steps {
  float: right;
  margin: 10px;
  z-index: 10;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.show-steps:hover,
.hide-steps:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

/* Hide the button on mobile devices */
@media (max-width: 768px) {
  .show-steps,
  .hide-steps {
    display: none;
  }
}
