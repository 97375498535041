.control-families-container {
  padding: 20px;
}

.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  padding: 20px;
  box-shadow: 24;
  border-radius: 8px;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.full-width-table {
  width: 100%;
}

.control-family-form {
  margin-bottom: 20px;
}

.control-family-form form {
  display: flex;
  flex-direction: column;
}

.control-family-form input {
  margin-bottom: 10px;
  padding: 8px;
  font-size: 16px;
}

.submit-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 16px;
  margin-right: 10px;
}

.control-families-table {
  width: 100%;
  border-collapse: collapse;
}

.control-families-table th,
.control-families-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.control-families-table th {
  background-color: #f4f4f4;
}

.control-families-table td {
  background-color: #fff;
}

.edit-button,
.delete-button {
  padding: 5px 10px;
  font-size: 14px;
  margin-right: 5px;
}

.edit-button:disabled,
.delete-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

.control-families-table td button {
  margin-right: 10px;
}

/* .control-families-container {
    padding: 20px;
  }
  
  .control-family-form {
    margin-bottom: 20px;
  }
  
  .control-family-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .submit-button, .cancel-button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #28a745;
    color: #fff;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: #fff;
  }
  
  .control-families-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .control-families-table th, .control-families-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .control-families-table th {
    background-color: #f4f4f4;
  }
   */
