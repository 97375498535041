@tailwind base;
@tailwind components;
@tailwind utilities;

/* index.css */
body {
  @apply bg-gray-100;
}

input {
  @apply border border-gray-300 p-2 rounded;
}

button {
  @apply bg-blue-500 text-white p-2 rounded;
}
